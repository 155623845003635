import React from "react";

import { Container } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import { track } from 'common/src/reusecore/Tracking';

import i18next from 'i18next';

import ContactWrapper from "./contact.style";
import { useTranslation, Trans } from 'common/src/reusecore/Trans';

const Contact = () => {
  const {t} = useTranslation();
  return (
    <ContactWrapper id="contact">
      <SectionTitle className="section-title" UniWidth="55%">
        <h4><Trans i18nKey="subscribe.announcement.0" /> &mdash; <Trans i18nKey="subscribe.announcement.1" /></h4>
        <h2>
          <Trans i18nKey="subscribe.title" />
        </h2>
      </SectionTitle>
      <Container>
        <div className="contact-email">
          <form method="post" action="/subscribe" id="form-contact">
            <input type="email" name="email" placeholder={t('subscribe.enter_email')} id="email-contact" />
            <input type="hidden" name="captcha" id="captcha-contact" />
            <input type="hidden" name="lng" id="lng-contact" />
            <Button
              className="banner-btn one"
              onClick={e => {
                e.preventDefault();
                const email = document.getElementById('email-contact').value;
                track('subscribeContact', {email});
                if (email.length == 0)
                  return;
                grecaptcha.ready(function() {
                  grecaptcha.execute('6LfPnPwUAAAAALmsYoJ2rqC_OJ2FnEz8CK9Ppfcr', {action: 'submit'}).then(function(token) {
                    document.getElementById('captcha-contact').value = token;
                    document.getElementById('lng-contact').value = i18next.language;
                    document.getElementById('form-contact').submit();
                  });
                });
              }}
            >
              <Trans i18nKey="subscribe.action" />
            </Button>
          </form>
          <div className="privacy">
            <Trans i18nKey="subscribe.disclaimer">
              By clicking subscribe you agree to our <a href="/privacy">privacy policy</a>.
            </Trans>
          </div>
        </div>
      </Container>
    </ContactWrapper>
  );
};

export default Contact;
